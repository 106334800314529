/* eslint-disable react/prop-types */
import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
const ContactPageTemplate = ({ data }) => {
  const { body, title, subTitle, featuredImage, address, phone, email } =
    data.directus.contactPage
  return (
    <main className="Contact">
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content body={body} />
            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.co.uk/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <FormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </section>
      <GoogleMap />
    </main>
  )
}

const ContactPage = ({ data }) => (
  <Layout meta={data.directus.contactPage.SEO || false}>
    <ContactPageTemplate data={data} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ContactPage {
    directus {
      contactPage {
        title
        address
        email
        phone
        subTitle
        featuredImage {
          id
        }
        body
        SEO {
          image {
            id
          }
          title
          description
        }
      }
    }
  }
`

/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapPin } from 'react-feather'

let mapkey = 'AIzaSyA5bfnlIrm89weVPEOJJsC6S5j-NmDs5TA'
if (process.env.GATSBYMAPKEY) {
  mapkey = process.env.GATSBYMAPKEY
}
class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 53.0499801,
      lng: -1.28341
    },
    zoom: 16
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={53.0499801} lng={-1.283412} text="Field One" />
          <Marker
            lat={53.051468120397494}
            lng={-1.2843107425391034}
            text="Field Two"
          />
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMap

const Marker = ({ text }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ color: 'red' }}>
        <MapPin />
      </div>
      <div style={{ color: 'black' }}>{text}</div>
    </div>
  )
}
